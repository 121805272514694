// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/SemanticQueryCoalescingStrategy.ts"
);
import.meta.hot.lastModified = "1731736109699.1084";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Coalescing strategy.
 */
export enum SemanticQueryCoalescingStrategy {
  NONE = 'NONE',
  DM_GROUPING = 'DM_GROUPING',
}
