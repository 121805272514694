// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/SlackService.ts"
);
import.meta.hot.lastModified = "1731736113099.0889";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'

export class SlackService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Events
   * @returns any Successful Response
   * @throws ApiError
   */
  public events(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/slack/events',
    })
  }

  /**
   * Install
   * @returns any Successful Response
   * @throws ApiError
   */
  public install(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/slack/install',
    })
  }

  /**
   * Oauth Redirect
   * @returns any Successful Response
   * @throws ApiError
   */
  public oauthRedirect(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/slack/oauth_redirect',
    })
  }
}
