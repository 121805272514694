import { type POJO, type Primitive } from '@/types'

/**
 * Checks if value is the language type of Object. (e.g. arrays, functions, objects, regexes, new Number(0),
 * and new String('')). This is identical to lodash's isObject implementation.
 * @see {@link https://github.com/lodash/lodash/blob/2da024c3b4f9947a48517639de7560457cd4ec6c/isObject.js#L24}
 *
 * @param obj The value to check.
 * @return Returns true if obj is an object, else false.
 */
export function isObject(obj: unknown): obj is object {
  const type = typeof obj
  return obj != null && (type === 'object' || type === 'function')
}

/**
 * Check if a variable is a POJO (not null, not array, but a simple `{ ... }`)
 * TS will narrow the type of the variable in the truthy code path to Record<PropertyKey, unknown>.
 * Example: const myObj = isPOJO(someUnknownVariable) ? someUnknownVariable : {};
 */
export function isPOJO(obj: unknown): obj is POJO {
  return typeof obj === 'object' && obj !== null && !Array.isArray(obj)
}

export function isPrimitive(item: unknown): item is Primitive {
  return (
    item === null ||
    typeof item === 'boolean' ||
    typeof item === 'undefined' ||
    typeof item === 'string' ||
    typeof item === 'number' ||
    typeof item === 'bigint' ||
    typeof item === 'symbol'
  )
}

export function isEnumMatch<T extends POJO>(
  value: unknown,
  enumType: T,
): value is T {
  return Object.values(enumType).includes(value as T)
}

export function isHTMLElement(el: unknown): el is HTMLElement {
  return el instanceof HTMLElement
}

// The same as above, but also includes SVGs and other elements which do not
// implement the HTMLElement interface
export function isDOMElement(el: unknown): el is Element {
  return el instanceof Element
}
