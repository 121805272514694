// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/LoggingService.ts"
);
import.meta.hot.lastModified = "1731736112883.09";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BrowserLog } from '../models/BrowserLog'

import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'

export class LoggingService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Log
   * Receive logs from the browser and display them on the server.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public log(
    accountName: string,
    requestBody: BrowserLog,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/logging/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
