import { FilterOperator, LogLevel } from '~/api'

export const MOONLIGHT_RESPONSE_PREFIX = 'Okay, I will run'

export function getGenericMoonlightResponse(question?: string | null) {
  return `${MOONLIGHT_RESPONSE_PREFIX} '${question ?? ''}'`
}

export const DEFAULT_LOG_LEVEL: LogLevel = LogLevel.INFO

export const empties = {
  function: () => {},
  objectGetter: () => ({}),
  object: {},
  array: [],
}

export const truthyFn = () => true

const KB = 1024
export const fileSize = {
  KB,
  MB: KB ** 2,
  GB: KB ** 3,
}

export const textIcons = {
  returnKey: '↵',
  commandKey: '⌘',
  commandReturnKey: '⌘↵',
  escapeKey: 'ESC',
  equals: '=',
  notEquals: '≠',
  gt: '>',
  gte: '≥',
  lt: '<',
  lte: '≤',
  contains: 'CT',
  notContains: 'NCT',
  startsWith: 'SW',
  notStartsWith: 'NSW',
  endsWith: 'EW',
  notEndsWith: 'NEW',
  in: 'IN',
  notIn: 'NIN',
  between: 'BT',
  notBetween: 'NBT',
  nin: 'NIN',
  blank: 'BL',
  notBlank: 'NBL',
  unknown: '?',
  arrowRight: '→',
  arrowLeft: '←',
  arrowLeftRight: '↔',
  arrowUp: '↑',
  arrowDown: '↓',
  arrowUpDown: '↕',
  arrowUpDownLeftRight: '↕↔',
  dot: '·',
}
export type TextIcon = keyof typeof textIcons

export function getOperatorIconName(operatorIn: FilterOperator): TextIcon {
  switch (operatorIn) {
    case FilterOperator.EQUALS:
      return 'equals'
    case FilterOperator.NOT_EQUALS:
      return 'notEquals'
    case FilterOperator.CONTAINS:
      return 'contains'
    case FilterOperator.NOT_CONTAINS:
      return 'notContains'
    case FilterOperator.STARTS_WITH:
      return 'startsWith'
    case FilterOperator.NOT_STARTS_WITH:
      return 'notStartsWith'
    case FilterOperator.ENDS_WITH:
      return 'endsWith'
    case FilterOperator.NOT_ENDS_WITH:
      return 'notEndsWith'
    case FilterOperator.IN:
      return 'in'
    case FilterOperator.NOT_IN:
      return 'notIn'
    case FilterOperator.BETWEEN:
      return 'between'
    case FilterOperator.NOT_BETWEEN:
      return 'notBetween'
    case FilterOperator.GREATER_THAN:
      return 'gt'
    case FilterOperator.GREATER_THAN_OR_EQUAL_TO:
      return 'gte'
    case FilterOperator.LESS_THAN:
      return 'lt'
    case FilterOperator.LESS_THAN_OR_EQUAL_TO:
      return 'lte'
    case FilterOperator.NOT_BLANK:
      return 'notBlank'
    case FilterOperator.BLANK:
      return 'blank'
    default:
      return 'unknown'
  }
}

export { LogLevel }
