// general purpose utility hooks.

import { useEffect, useRef, useState } from 'react'

import { empties } from '@/constants'

// copied from:
// https://github.com/uidotdev/usehooks/blob/dfa6623fcc2dcad3b466def4e0495b3f38af962b/index.js#L1241
export function useThrottle<T>(value: T, interval = 500) {
  const [throttledValue, setThrottledValue] = useState(value)
  const lastUpdated = useRef<null | number>(null)

  useEffect(() => {
    const now = Date.now()

    if (lastUpdated.current && now >= lastUpdated.current + interval) {
      lastUpdated.current = now
      setThrottledValue(value)
    } else {
      const id = window.setTimeout(() => {
        lastUpdated.current = now
        setThrottledValue(value)
      }, interval)

      return () => window.clearTimeout(id)
    }
  }, [value, interval])

  return throttledValue
}

// copied from:
// https://github.com/uidotdev/usehooks/blob/dfa6623fcc2dcad3b466def4e0495b3f38af962b/index.js#L239C1-L253C2
export function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export function useWindowListener<K extends keyof WindowEventMap>(
  getArgs: () => Parameters<typeof window.addEventListener<K>>,
  deps: React.DependencyList = empties.array,
): void {
  const [type, listener, options] = getArgs()
  useEffect(() => {
    window.addEventListener<K>(type, listener, options)
    return () => {
      window.removeEventListener<K>(type, listener, options)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps])
}
