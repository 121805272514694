// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/V3Service.ts"
);
import.meta.hot.lastModified = "1731736113759.0852";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgentAPI } from '../models/AgentAPI'
import type { ChatAPICreate } from '../models/ChatAPICreate'
import type { ChatAPIResponse } from '../models/ChatAPIResponse'
import type { ChatAPIResponsePage } from '../models/ChatAPIResponsePage'
import type { ChatAPIUpdate } from '../models/ChatAPIUpdate'
import type { ChatMessagePromptlogInDB } from '../models/ChatMessagePromptlogInDB'
import type { ChatsFilter } from '../models/ChatsFilter'
import type { ChatsSort } from '../models/ChatsSort'
import type { DataAssetAPIPage } from '../models/DataAssetAPIPage'
import type { DataPage } from '../models/DataPage'
import type { MessageAPIResponse } from '../models/MessageAPIResponse'
import type { MessageAPIResponsePage } from '../models/MessageAPIResponsePage'
import type { MessageAPISendV3 } from '../models/MessageAPISendV3'
import type { SuggestionAPI } from '../models/SuggestionAPI'
import type { Task } from '../models/Task'
import type { WidgetAPICreateResponse } from '../models/WidgetAPICreateResponse'
import type { WidgetAPIGetResponse } from '../models/WidgetAPIGetResponse'
import type { WidgetAPIUpdateRequest } from '../models/WidgetAPIUpdateRequest'
import type { WidgetAPIUpdateResponse } from '../models/WidgetAPIUpdateResponse'

import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'

export class V3Service {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Chat Agents
   * Get list of available agents by name.
   * @param accountName The name of the organization or account.
   * @param sessionId
   * @returns AgentAPI Successful Response
   * @throws ApiError
   */
  public getChatAgents(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<AgentAPI>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/agents',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Chat
   * Create a chat. This will not send a message to the chat.
   *
   * Note that you can also create a chat using the send message endpoint. Doing so will
   * let you skip making a separate call to create the chat.
   * @param accountName The name of the organization or account.
   * @param requestBody
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public createChat(
    accountName: string,
    requestBody: ChatAPICreate,
    sessionId?: string | null,
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Chats
   * Read all chats.
   * @param accountName The name of the organization or account.
   * @param sortAscending Sort ascending. If false, sort descending.
   * @param sortBy The field to sort by.
   * @param filterBy Filter for a particular kind of chats.
   * @param limit The maximum number of chats to include in the response.
   * @param offset The maximum number of chats to include in the response.
   * @param sessionId
   * @returns ChatAPIResponsePage Successful Response
   * @throws ApiError
   */
  public getChats(
    accountName: string,
    sortAscending: boolean = true,
    sortBy?: ChatsSort,
    filterBy?: ChatsFilter,
    limit: number = 100,
    offset?: number,
    sessionId?: string | null,
  ): CancelablePromise<ChatAPIResponsePage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        sort_ascending: sortAscending,
        sort_by: sortBy,
        filter_by: filterBy,
        limit: limit,
        offset: offset,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Chat
   * Delete a chat.
   *
   * CAUTION: This is unrecoverable. All messages associated with the chat will be deleted.
   * @param accountName The name of the organization or account.
   * @param chatId The ID of the chat.
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public deleteChat(
    accountName: string,
    chatId: string,
    sessionId?: string | null,
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}',
      path: {
        account_name: accountName,
        chat_id: chatId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Chat
   * Update a chat.
   * @param accountName The name of the organization or account.
   * @param chatId The ID of the chat.
   * @param requestBody
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public updateChat(
    accountName: string,
    chatId: string,
    requestBody: ChatAPIUpdate,
    sessionId?: string | null,
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}',
      path: {
        account_name: accountName,
        chat_id: chatId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Chat
   * Read a single chat.
   *
   * Note that this does NOT include the messages of the chat. See the get
   * chat messages endpoint (GET api/v3/orgs/{account_name}/chats/{chat_id}/messages/) for that.
   * @param accountName The name of the organization or account.
   * @param chatId The ID of the chat.
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public getChat(
    accountName: string,
    chatId: string,
    sessionId?: string | null,
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}',
      path: {
        account_name: accountName,
        chat_id: chatId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get By Slack Id
   * Read a single chat by slack ID.
   *
   * Note that this does NOT include the messages of the chat. See the get
   * chat messages endpoint (GET api/v3/orgs/{account_name}/chats/{chat_id}/messages/) for that.
   * If a chat with the given slack ID does not exist, then this will 404.
   * @param accountName The name of the organization or account.
   * @param slackId The slack ID of the chat.
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public getBySlackId(
    accountName: string,
    slackId: string,
    sessionId?: string | null,
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/slack',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        slack_id: slackId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Chat Promptlogs
   * Get chat message promptlogs sorted in descending order.
   * @param accountName The name of the organization or account.
   * @param chatId The ID of the chat.
   * @param sessionId
   * @returns ChatMessagePromptlogInDB Successful Response
   * @throws ApiError
   */
  public getChatPromptlogs(
    accountName: string,
    chatId: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<ChatMessagePromptlogInDB>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/promptlogs',
      path: {
        account_name: accountName,
        chat_id: chatId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Chat Messages
   * Get messages in a chat.
   *
   * This paginated endpoint returns a chat with up to the requested number of
   * messages. Note that the messages are ordered from newest to oldest.
   * @param accountName The name of the organization or account.
   * @param chatId The ID of the chat.
   * @param limit The maximum number of messages to include in the response.
   * @param offset The messages to skip. The response will not include indexes greater than the offset. Equivalent of 'skip' in database queries.
   * @param sessionId
   * @returns MessageAPIResponsePage Successful Response
   * @throws ApiError
   */
  public getChatMessages(
    accountName: string,
    chatId: string,
    limit: number = 20,
    offset?: number,
    sessionId?: string | null,
  ): CancelablePromise<MessageAPIResponsePage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/messages',
      path: {
        account_name: accountName,
        chat_id: chatId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        offset: offset,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Send Interrupt
   * Send a interrupt signal in a chat.
   *
   * Note that this sends the signal regardless of whether the AI is currently
   * generating
   * @param accountName The name of the organization or account.
   * @param chatId The ID of the chat.
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public sendInterrupt(
    accountName: string,
    chatId: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/interrupt',
      path: {
        account_name: accountName,
        chat_id: chatId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Clone Chat
   * Clone a chat with the new user as the creator.
   * @param accountName The name of the organization or account.
   * @param chatId The ID of the chat.
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public cloneChat(
    accountName: string,
    chatId: string,
    sessionId?: string | null,
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/clone',
      path: {
        account_name: accountName,
        chat_id: chatId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Run Eval On Chat
   * Run eval on all of the messages sent in the reference chat.
   * @param accountName
   * @param chatId The ID of the chat.
   * @param deleteNewChat
   * @param runGroupChat
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public runEvalOnChat(
    accountName: string,
    chatId: string,
    deleteNewChat: boolean = true,
    runGroupChat: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/eval',
      path: {
        account_name: accountName,
        chat_id: chatId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        delete_new_chat: deleteNewChat,
        run_group_chat: runGroupChat,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Run Eval Chats
   * Run eval on reference chats.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param maxEvalChats
   * @param deleteNewChat
   * @param runGroupChat
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public runEvalChats(
    accountName: string,
    starSchemaDataSourceId: string,
    maxEvalChats?: number | null,
    deleteNewChat: boolean = true,
    runGroupChat: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/{star_schema_data_source_id}/eval_chats',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        max_eval_chats: maxEvalChats,
        delete_new_chat: deleteNewChat,
        run_group_chat: runGroupChat,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Send Message
   * Send a message.
   *
   * This endpoint returns a server sent event stream composed of `MessageAPIResponse` objects.
   * The stream will close when the model is done generating responses and is ready for more
   * input.
   *
   * If a chat_id is provided, the message will be sent to that chat. A chat will be created and
   * the MessageAPIResponse will contain the new chat_id. If the chat does not have a name, then
   * a name will be generated asynchronously. It should be available within a few seconds.
   * @param accountName The name of the organization or account.
   * @param requestBody
   * @param sessionId
   * @returns MessageAPIResponse Message sent successfully. Returns an event stream of `MessageAPIResponse` objects.
   * @throws ApiError
   */
  public sendMessage(
    accountName: string,
    requestBody: MessageAPISendV3,
    sessionId?: string | null,
  ): CancelablePromise<MessageAPIResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/messages/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `The chat or message could not be found.`,
        409: `Chat is busy. Returns an event stream of the running chat.`,
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Message Promptlog
   * Get chat message promptlogs.
   * @param accountName The name of the organization or account.
   * @param messageId The id of a message in a chat.
   * @param sessionId
   * @returns ChatMessagePromptlogInDB Successful Response
   * @throws ApiError
   */
  public getMessagePromptlog(
    accountName: string,
    messageId: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<ChatMessagePromptlogInDB>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/promptlogs',
      path: {
        account_name: accountName,
        message_id: messageId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Refresh Query Message
   * Refresh the SQL table in a chat message.
   *
   * This will return an error if the message does not contain a table ID.
   * Note that you will need to refetch the data from the data endpoint to see the updates.
   * @param accountName The name of the organization or account.
   * @param messageId The id of a message in a chat.
   * @param sessionId
   * @returns MessageAPIResponse The table associated with the message was refreshed successfully.
   * @throws ApiError
   */
  public refreshQueryMessage(
    accountName: string,
    messageId: string,
    sessionId?: string | null,
  ): CancelablePromise<MessageAPIResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/refresh',
      path: {
        account_name: accountName,
        message_id: messageId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        400: `Bad request. Verify that the message contains a table ID.`,
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Query Message Table Data
   * Get the table data associated with a message.
   *
   * This will return an error if the message does not contain a table ID.
   * @param accountName
   * @param messageId The id of a message in a chat.
   * @param limit The maximum number of rows to include in the response.
   * @param skip The number of rows to skip. Equivalent of 'skip' in database queries.
   * @param sort The columns to sort by. Prefix with '-' for descending order.
   * @param sessionId
   * @returns DataPage Successful Response
   * @throws ApiError
   */
  public readQueryMessageTableData(
    accountName: string,
    messageId: string,
    limit: number = 100,
    skip?: number,
    sort?: Array<string> | null,
    sessionId?: string | null,
  ): CancelablePromise<DataPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/data',
      path: {
        account_name: accountName,
        message_id: messageId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        skip: skip,
        sort: sort,
      },
      errors: {
        400: `Bad request. Verify that the message contains a table ID.`,
        404: `The item could not be found.`,
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Query Message Table Data As Csv
   * Read message table data as a CSV.
   *
   * This will return an error if the message does not contain a table ID.
   * @param accountName
   * @param messageId The id of a message in a chat.
   * @param sessionId
   * @returns any The table data as a CSV file.
   * @throws ApiError
   */
  public readQueryMessageTableDataAsCsv(
    accountName: string,
    messageId: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/data/csv',
      path: {
        account_name: accountName,
        message_id: messageId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        400: `Bad request. Verify that the message contains a table ID.`,
        404: `The item could not be found.`,
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Widget
   * Create a widget.
   *
   * For now, we only allow one widget per account.
   * @param accountName The name of the organization or account.
   * @param key
   * @param sessionId
   * @returns WidgetAPICreateResponse Successful Response
   * @throws ApiError
   */
  public createWidget(
    accountName: string,
    key?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<WidgetAPICreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/widgets/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        key: key,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Widget For Account
   * Get widget by account ID.
   * @param accountName The name of the organization or account.
   * @param sessionId
   * @returns WidgetAPIGetResponse Successful Response
   * @throws ApiError
   */
  public getWidgetForAccount(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<WidgetAPIGetResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/widgets/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Widget
   * Get widget by ID.
   * @param accountName The name of the organization or account.
   * @param widgetId
   * @param sessionId
   * @returns WidgetAPIGetResponse Successful Response
   * @throws ApiError
   */
  public getWidget(
    accountName: string,
    widgetId: string,
    sessionId?: string | null,
  ): CancelablePromise<WidgetAPIGetResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/widgets/{widget_id}',
      path: {
        account_name: accountName,
        widget_id: widgetId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Widget
   * Update widget by ID.
   *
   * Currently this endpoint supports updating the `options` and the `key` fields.
   * @param accountName
   * @param widgetId
   * @param requestBody
   * @param sessionId
   * @returns WidgetAPIUpdateResponse Successful Response
   * @throws ApiError
   */
  public updateWidget(
    accountName: string,
    widgetId: string,
    requestBody: WidgetAPIUpdateRequest,
    sessionId?: string | null,
  ): CancelablePromise<WidgetAPIUpdateResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v3/orgs/{account_name}/widgets/{widget_id}',
      path: {
        account_name: accountName,
        widget_id: widgetId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Widget
   * Delete widget by ID.
   * @param accountName
   * @param widgetId
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public deleteWidget(
    accountName: string,
    widgetId: string,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v3/orgs/{account_name}/widgets/{widget_id}',
      path: {
        account_name: accountName,
        widget_id: widgetId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Data Assets For Account
   * Get data assets for an account.
   * @param accountName The name of the organization or account.
   * @param limit
   * @param offset
   * @param sessionId
   * @returns DataAssetAPIPage Successful Response
   * @throws ApiError
   */
  public getDataAssetsForAccount(
    accountName: string,
    limit: number = 100,
    offset?: number,
    sessionId?: string | null,
  ): CancelablePromise<DataAssetAPIPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/data_assets/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        offset: offset,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Suggestions For Account
   * Get suggested prompts for an account across all datasets in the account.
   * @param accountName The name of the organization or account.
   * @param limit
   * @param sessionId
   * @returns SuggestionAPI Successful Response
   * @throws ApiError
   */
  public getSuggestionsForAccount(
    accountName: string,
    limit: number = 5,
    sessionId?: string | null,
  ): CancelablePromise<Array<SuggestionAPI>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/data_assets/suggestions',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Events
   * @returns any Successful Response
   * @throws ApiError
   */
  public events(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/slack/events',
    })
  }

  /**
   * Install
   * @returns any Successful Response
   * @throws ApiError
   */
  public install(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/slack/install',
    })
  }

  /**
   * Oauth Redirect
   * @returns any Successful Response
   * @throws ApiError
   */
  public oauthRedirect(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/slack/oauth_redirect',
    })
  }
}
