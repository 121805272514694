import { z } from 'zod'

import { type Account, type Domain } from '~/api'

export const domain: z.ZodType<Domain> = z.object({
  id: z.string(),
  account_id: z.string().optional().nullable(),
  name: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
})

export const account: z.ZodType<Account> = z.object({
  name: z.string(),
  display_name: z.string(),
  avatar: z.string().nullable().optional(),
  colors: z.array(z.string()).nullable().optional(),
  id: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  allowed_domains: z.array(domain),
})
