// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/UsersService.ts"
);
import.meta.hot.lastModified = "1731736113335.0876";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Account } from '../models/Account'
import type { Body_create_user_open_api_v1_users_open_post } from '../models/Body_create_user_open_api_v1_users_open_post'
import type { Body_update_user_me_api_v1_users_me_put } from '../models/Body_update_user_me_api_v1_users_me_put'
import type { JWTToken } from '../models/JWTToken'
import type { Preregistration } from '../models/Preregistration'
import type { PreregistrationCreate } from '../models/PreregistrationCreate'
import type { User } from '../models/User'
import type { UserCreate } from '../models/UserCreate'
import type { UserUpdate } from '../models/UserUpdate'

import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'

export class UsersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Read Users
   * Retrieve users.
   * @param skip
   * @param limit
   * @param email
   * @param sessionId
   * @returns User Successful Response
   * @throws ApiError
   */
  public readUsers(
    skip?: number,
    limit: number = 100,
    email?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<Array<User>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/users/',
      cookies: {
        session_id: sessionId,
      },
      query: {
        skip: skip,
        limit: limit,
        email: email,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create User
   * Create new user.
   * @param requestBody
   * @param sessionId
   * @returns User Successful Response
   * @throws ApiError
   */
  public createUser(
    requestBody: UserCreate,
    sessionId?: string | null,
  ): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/users/',
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update User Me
   * Update own user.
   * @param sessionId
   * @param requestBody
   * @returns User Successful Response
   * @throws ApiError
   */
  public updateUserMe(
    sessionId?: string | null,
    requestBody?: Body_update_user_me_api_v1_users_me_put,
  ): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/users/me',
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read User Me
   * Get current user.
   * @param sessionId
   * @returns User Successful Response
   * @throws ApiError
   */
  public readUserMe(sessionId?: string | null): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/users/me',
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read User Jwt Token
   * Get current user's jwt token.
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public readUserJwtToken(
    sessionId?: string | null,
  ): CancelablePromise<JWTToken | null> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/users/jwt_token',
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Accounts By Current User
   * Get accounts by user.
   * @param sessionId
   * @returns Account Successful Response
   * @throws ApiError
   */
  public readAccountsByCurrentUser(
    sessionId?: string | null,
  ): CancelablePromise<Array<Account>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/users/accounts',
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Check Username Exists
   * Check if username exists.
   * @param username
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public checkUsernameExists(username: string): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/users/username_exists',
      query: {
        username: username,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create User Open
   * Create new user without the need to be logged in.
   * @param requestBody
   * @returns User Successful Response
   * @throws ApiError
   */
  public createUserOpen(
    requestBody: Body_create_user_open_api_v1_users_open_post,
  ): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/users/open',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read User By Id
   * Get a specific user by id.
   * @param userId
   * @param userEmail
   * @param sessionId
   * @returns User Successful Response
   * @throws ApiError
   */
  public readUserById(
    userId: string | null,
    userEmail?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/users/{user_id}',
      path: {
        user_id: userId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        user_email: userEmail,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update User
   * Update a user.
   * @param userId
   * @param requestBody
   * @param userEmail
   * @param sessionId
   * @returns User Successful Response
   * @throws ApiError
   */
  public updateUser(
    userId: string | null,
    requestBody: UserUpdate,
    userEmail?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/users/{user_id}',
      path: {
        user_id: userId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        user_email: userEmail,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Preregister User
   * Create a preregistration.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns Preregistration Successful Response
   * @throws ApiError
   */
  public preregisterUser(
    accountName: string,
    requestBody: PreregistrationCreate,
    sessionId?: string | null,
  ): CancelablePromise<Preregistration> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/users/preregistration',
      cookies: {
        session_id: sessionId,
      },
      query: {
        account_name: accountName,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Resend Email
   * Re-send a preregistration welcome email.
   * @param preregistrationId
   * @param accountName
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public resendEmail(
    preregistrationId: string,
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/users/preregistration/{preregistration_id}/resend-invite',
      path: {
        preregistration_id: preregistrationId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        account_name: accountName,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Compelete Preregistration
   * Register a user.
   * @param preregistrationId
   * @param requestBody
   * @returns User Successful Response
   * @throws ApiError
   */
  public compeletePreregistration(
    preregistrationId: string,
    requestBody: UserCreate,
  ): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/users/preregistration/{preregistration_id}',
      path: {
        preregistration_id: preregistrationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
