// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/ConnectionPolyType.ts"
);
import.meta.hot.lastModified = "1731736109643.109";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumerates the possible connection types.
 */
export enum ConnectionPolyType {
  BIGQUERY = 'bigquery',
  DATABRICKS = 'databricks',
  CONNECTION = 'connection',
  POSTGRES = 'postgres',
  REDSHIFT = 'redshift',
  S3 = 's3',
  SNOWFLAKE = 'snowflake',
  TERADATA = 'teradata',
}
