// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/DisplayType.ts"
);
import.meta.hot.lastModified = "1731736109663.1086";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Display type enum.
 */
export enum DisplayType {
  UNKNOWN = 'unknown',
  TEXT = 'text',
  NUMERIC = 'numeric',
  DATE = 'date',
  DATETIME = 'datetime',
  BOOLEAN = 'boolean',
  MONTH = 'month',
  WEEK = 'week',
  QUARTER = 'quarter',
  DAY_OF_WEEK = 'day_of_week',
  YEAR = 'year',
  YEAR_QUARTER = 'year_quarter',
  YEAR_MONTH = 'year_month',
  YEAR_WEEK = 'year_week',
  YEAR_MONTH_DAY = 'year_month_day',
  COUNTRY = 'country',
  COUNTRY_CODE = 'country_code',
  CONTINENT = 'continent',
  CONTINENT_CODE = 'continent_code',
  REGION = 'region',
  REGION_CODE = 'region_code',
  LAT_LONG = 'lat_long',
  ZIP_CODE = 'zip_code',
  ID = 'id',
  URL = 'url',
  EMAIL = 'email',
  PHONE = 'phone',
  COUNT = 'count',
  FLOAT = 'float',
  PERCENT = 'percent',
  FEET = 'feet',
  METERS = 'meters',
  SQUARE_FEET = 'square_feet',
  SQUARE_METERS = 'square_meters',
  CURRENCY_USD = 'currency_usd',
  CURRENCY_GBP = 'currency_gbp',
  CURRENCY_EUR = 'currency_eur',
  CURRENCY_AUD = 'currency_aud',
  CURRENCY_USD_PER_SQUARE_FEET = 'currency_usd_per_square_feet',
  CURRENCY_USD_PER_SQUARE_METERS = 'currency_usd_per_square_meters',
}
