// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/DMFilter.ts"
);
import.meta.hot.lastModified = "1731736109651.1086";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DMFilter {
  ALL = 'ALL',
  VERIFIED_ONLY = 'VERIFIED_ONLY',
}
