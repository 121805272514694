// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/RetrievalMethod.ts"
);
import.meta.hot.lastModified = "1731736109695.1084";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Retrieval Method.
 */
export enum RetrievalMethod {
  EMB = 'emb',
  TXT = 'txt',
  HYBRID = 'hybrid',
}
