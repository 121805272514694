import { z } from 'zod'

import {
  Combination,
  DisplayType,
  FilterCategory,
  FilterType,
  KnowledgeOriginFilter,
  KnowledgeStateFilter,
  type NamedFilterBase,
} from '~/api'

export type OptionsT = z.infer<typeof optionsSchema>
export const optionsSchema = z.object({
  limit: z.number().optional(),
  skip: z.number().optional(),
  search: z.string().optional(),
  state: z.nativeEnum(KnowledgeStateFilter).optional(),
  origin: z.nativeEnum(KnowledgeOriginFilter).optional(),
  dataSourceIds: z.array(z.string()).optional(),
})
export const defaultOptions = {
  state: KnowledgeStateFilter.VERIFIED,
  origin: KnowledgeOriginFilter.ALL,
  limit: 50,
  skip: 0,
}

export const starSchemaDataSourceUpdate = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
})

export const knowledgeStateFilter = z.nativeEnum(KnowledgeStateFilter)
export const knowledgeOriginFilter = z.nativeEnum(KnowledgeOriginFilter)

export const createDimensionMetric = z.preprocess(
  // Preprocess the data to assign the same value to the `name` and `display_name`
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (data: any) => {
    if (typeof data === 'object' && data !== null && 'display_name' in data) {
      data.name = data.display_name
    }
    return data
  },
  z.object({
    name: z.string().min(1),
    display_name: z.string().min(1),
    description: z.string().nullish(),
    expr: z.string().min(1),
  }),
)

export const updateDimension = z.object({
  description: z.string().optional(),
  sql: z.string().optional(),
  display_name: z.string().optional(),
  expr_type: z.nativeEnum(FilterCategory).optional(),
  display_type: z.nativeEnum(DisplayType).optional(),
  group_id: z.string().optional(),
  data_source_id: z.string().optional(),
})

export const updateMetric = z.object({
  description: z.string().optional(),
  sql: z.string().optional(),
  display_name: z.string().optional(),
  expr_type: z.nativeEnum(FilterCategory).optional(),
  display_type: z.nativeEnum(DisplayType).optional(),
})

export const updateNamedFilter = z.object({
  display_name: z.string().optional(),
  description: z.string().optional(),
  combination: z.nativeEnum(Combination).optional(),
  filter_type: z.nativeEnum(FilterType).optional(),
  is_enabled: z.boolean().optional(),
})

// The property names of the `NamedFilterUpdateAPI` and the `NamedFilterBase`
// are not aligned. The backend does this mapping themselves manually too.
export function updateToFilter(
  update: z.infer<typeof updateNamedFilter>,
): Partial<NamedFilterBase> {
  return {
    name: update.display_name,
    description: update.description,
  }
}

export const updateDimensionMetric = z.intersection(
  updateDimension,
  updateMetric,
)
export const updateMetrics = z.array(updateMetric.extend({ id: z.string() }))
export const updateDimensions = z.array(
  updateDimension.extend({ id: z.string() }),
)
export const updateNamedFilters = z.array(
  updateNamedFilter.extend({ id: z.string() }),
)
