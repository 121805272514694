// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/ChatsFilter.ts"
);
import.meta.hot.lastModified = "1731736109639.109";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Filter chats by.
 */
export enum ChatsFilter {
  IS_PINNED = 'is_pinned',
  IS_BY_USER = 'is_by_user',
  IS_EVAL = 'is_eval',
  ALL = 'all',
}
