/** DB Create Schemas */

import { z } from 'zod'

import { type RequiredPartialNonNullish } from '@/types'

import {
  BigQueryLocation,
  ConnectionPolyType,
  type SnowflakeConnectionAPICreate,
  type RedshiftConnectionCreate,
  type DatabricksConnectionCreate,
  type BigQueryConnectionAPICreate,
  type TeradataConnectionCreate,
  type PostgresConnectionCreate,
  type TeradataConnectionUpdate,
  type SnowflakeConnectionUpdate,
  type DatabricksConnectionUpdate,
  type RedshiftConnectionUpdate,
  type BigQueryConnectionAPIUpdate,
  type PostgresConnectionUpdate,
} from '~/api'

export const connectionTypes: [ConnectionPolyType, string][] = [
  [ConnectionPolyType.SNOWFLAKE, 'Snowflake'],
  [ConnectionPolyType.BIGQUERY, 'BigQuery'],
  [ConnectionPolyType.DATABRICKS, 'Databricks'],
  [ConnectionPolyType.REDSHIFT, 'Redshift'],
  [ConnectionPolyType.TERADATA, 'Teradata'],
  [ConnectionPolyType.POSTGRES, 'PostgreSQL'],
]

export function getConnectionLabel(type?: ConnectionPolyType) {
  return connectionTypes.find(([t]) => t === type)?.[1] ?? ''
}

export const snowflakeCreateSchema = z.object({
  type: z.literal(ConnectionPolyType.SNOWFLAKE),
  is_draft: z.coerce.boolean().optional(),
  name: z.string(),
  user: z.string(),
  account: z.string(),
  region: z.string().nullish(),
  cloud: z.string().nullish(),
  account_locator: z.string().nullish(),
  role: z.string().nullish(),
  ns_warehouse: z.string(),
  ns_database: z.string().optional(),
  ns_schema: z.string().optional(),
  max_tables: z.coerce.number().optional(),
  password: z.string(),
  create_db_and_schema: z.coerce.boolean().optional(),
})

export const databricksCreateSchema = z.object({
  type: z.literal(ConnectionPolyType.DATABRICKS),
  is_draft: z.coerce.boolean().optional(),
  name: z.string(),
  host: z.string(),
  http_path: z.string(),
  access_token: z.string(),
  ns_schema: z.string().optional(),
  ns_database: z.string().optional(),
  max_tables: z.coerce.number().optional(),
})

export const bigQueryCreateSchema = z.object({
  type: z.literal(ConnectionPolyType.BIGQUERY),
  is_draft: z.coerce.boolean().optional(),
  name: z.string(),
  keyfile: z.string(),
  location: z.nativeEnum(BigQueryLocation),
  ns_schema: z.string().nullish(),
  ns_database: z.string().nullish(),
  max_tables: z.coerce.number().optional(),
})

export const teradataCreateSchema = z.object({
  type: z.literal(ConnectionPolyType.TERADATA),
  is_draft: z.coerce.boolean().optional(),
  name: z.string(),
  host: z.string(),
  user: z.string(),
  password: z.string(),
  ns_schema: z.string().optional(),
  ns_database: z.string().optional(),
  max_tables: z.coerce.number().optional(),
})

export const postgresCreateSchema = z.object({
  type: z.literal(ConnectionPolyType.POSTGRES),
  is_draft: z.coerce.boolean().optional(),
  name: z.string(),
  user: z.string(),
  dbname: z.string(),
  host: z.string(),
  port: z.coerce.number(),
  password: z.string(),
  ns_schema: z.string().optional(),
  ns_database: z.string().optional(),
  max_tables: z.coerce.number().optional(),
})

export const redshiftCreateSchema = z.object({
  type: z.literal(ConnectionPolyType.REDSHIFT),
  is_draft: z.coerce.boolean().optional(),
  name: z.string(),
  user: z.string(),
  host: z.string(),
  port: z.coerce.number(),
  ns_database: z.string(),
  ns_schema: z.string().optional(),
  max_tables: z.coerce.number().optional(),
  password: z.string(),
  region: z.string(),
})

export const createConnectionSchema = z.discriminatedUnion('type', [
  snowflakeCreateSchema,
  databricksCreateSchema,
  bigQueryCreateSchema,
  teradataCreateSchema,
  postgresCreateSchema,
  redshiftCreateSchema,
])

export const snowflakeConnectionAPICreate: z.ZodType<SnowflakeConnectionAPICreate> =
  snowflakeCreateSchema
export const databricksConnectionCreate: z.ZodType<DatabricksConnectionCreate> =
  databricksCreateSchema
export const redshiftConnectionCreate: z.ZodType<RedshiftConnectionCreate> =
  redshiftCreateSchema
export const bigQueryConnectionAPICreate: z.ZodType<BigQueryConnectionAPICreate> =
  bigQueryCreateSchema
export const teradataConnectionCreate: z.ZodType<TeradataConnectionCreate> =
  teradataCreateSchema
export const postgresConnectionCreate: z.ZodType<PostgresConnectionCreate> =
  postgresCreateSchema

/** DB Create Schemas End */

/** DB Update Schemas */

export const snowflakeUpdateSchema = snowflakeCreateSchema.partial().extend({
  type: z.literal(ConnectionPolyType.SNOWFLAKE),
})
export const teradataUpdateSchema = teradataCreateSchema.partial().extend({
  type: z.literal(ConnectionPolyType.TERADATA),
})
export const databricksUpdateSchema = databricksCreateSchema.partial().extend({
  type: z.literal(ConnectionPolyType.DATABRICKS),
})
export const redshiftUpdateSchema = redshiftCreateSchema.partial().extend({
  type: z.literal(ConnectionPolyType.REDSHIFT),
})
export const bigQueryUpdateSchema = bigQueryCreateSchema.partial().extend({
  type: z.literal(ConnectionPolyType.BIGQUERY),
})
export const postgresUpdateSchema = postgresCreateSchema.partial().extend({
  type: z.literal(ConnectionPolyType.POSTGRES),
})

export const updateConnectionSchema = z.discriminatedUnion('type', [
  snowflakeUpdateSchema,
  teradataUpdateSchema,
  databricksUpdateSchema,
  redshiftUpdateSchema,
  bigQueryUpdateSchema,
  postgresUpdateSchema,
])

export const snowflakeConnectionUpdate: z.ZodType<
  RequiredPartialNonNullish<SnowflakeConnectionUpdate, 'type'>
> = snowflakeUpdateSchema
export const teradataConnectionUpdate: z.ZodType<TeradataConnectionUpdate> =
  teradataUpdateSchema
export const databricksConnectionUpdate: z.ZodType<DatabricksConnectionUpdate> =
  databricksUpdateSchema
export const redshiftConnectionUpdate: z.ZodType<RedshiftConnectionUpdate> =
  redshiftUpdateSchema
export const bigQueryConnectionAPIUpdate: z.ZodType<BigQueryConnectionAPIUpdate> =
  bigQueryUpdateSchema
export const postgresConnectionUpdate: z.ZodType<PostgresConnectionUpdate> =
  postgresUpdateSchema

/** DB Update Schemas End */
