// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/BumblebeeDatasetUploadOptions.ts"
);
import.meta.hot.lastModified = "1731736111079.1006";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { M2MTokenCreds } from './M2MTokenCreds'
import type { StarSchemaDataSourceEnrichment } from './StarSchemaDataSourceEnrichment'

/**
 * Options for BumblebeeDataset Upload.
 */
export type BumblebeeDatasetUploadOptions = {
  existing_dataset?: BumblebeeDatasetUploadOptions.existing_dataset
  skip_validation_check?: boolean
  enrich_params?: StarSchemaDataSourceEnrichment
  allow_conflicting_feedback?: boolean
  clear_named_filters?: boolean
  clear_cells?: boolean
  mark_as_demo?: boolean
  m2m_creds?: M2MTokenCreds | null
}

export namespace BumblebeeDatasetUploadOptions {
  export enum existing_dataset {
    CLEAR = 'clear',
    IGNORE = 'ignore',
    REUSE = 'reuse',
  }
}
