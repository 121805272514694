import { isPOJO } from '@/guards'

import {
  type DimensionFilter,
  type MetricFilter,
  type NamedFilterBase,
} from '~/api'

export type AnyFilter = MetricFilter | DimensionFilter | NamedFilterBase
export function isNamedFilterBase(
  filter: AnyFilter,
): filter is NamedFilterBase {
  return (
    isPOJO(filter) &&
    'filter_options' in filter &&
    Array.isArray(filter.filter_options)
  )
}
