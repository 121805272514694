// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/MessagesService.ts"
);
import.meta.hot.lastModified = "1731736112911.09";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChatMessagePromptlogInDB } from '../models/ChatMessagePromptlogInDB'
import type { DataPage } from '../models/DataPage'
import type { MessageAPIResponse } from '../models/MessageAPIResponse'
import type { MessageAPISendV3 } from '../models/MessageAPISendV3'

import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'

export class MessagesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Send Message
   * Send a message.
   *
   * This endpoint returns a server sent event stream composed of `MessageAPIResponse` objects.
   * The stream will close when the model is done generating responses and is ready for more
   * input.
   *
   * If a chat_id is provided, the message will be sent to that chat. A chat will be created and
   * the MessageAPIResponse will contain the new chat_id. If the chat does not have a name, then
   * a name will be generated asynchronously. It should be available within a few seconds.
   * @param accountName The name of the organization or account.
   * @param requestBody
   * @param sessionId
   * @returns MessageAPIResponse Message sent successfully. Returns an event stream of `MessageAPIResponse` objects.
   * @throws ApiError
   */
  public sendMessage(
    accountName: string,
    requestBody: MessageAPISendV3,
    sessionId?: string | null,
  ): CancelablePromise<MessageAPIResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/messages/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `The chat or message could not be found.`,
        409: `Chat is busy. Returns an event stream of the running chat.`,
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Message Promptlog
   * Get chat message promptlogs.
   * @param accountName The name of the organization or account.
   * @param messageId The id of a message in a chat.
   * @param sessionId
   * @returns ChatMessagePromptlogInDB Successful Response
   * @throws ApiError
   */
  public getMessagePromptlog(
    accountName: string,
    messageId: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<ChatMessagePromptlogInDB>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/promptlogs',
      path: {
        account_name: accountName,
        message_id: messageId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Refresh Query Message
   * Refresh the SQL table in a chat message.
   *
   * This will return an error if the message does not contain a table ID.
   * Note that you will need to refetch the data from the data endpoint to see the updates.
   * @param accountName The name of the organization or account.
   * @param messageId The id of a message in a chat.
   * @param sessionId
   * @returns MessageAPIResponse The table associated with the message was refreshed successfully.
   * @throws ApiError
   */
  public refreshQueryMessage(
    accountName: string,
    messageId: string,
    sessionId?: string | null,
  ): CancelablePromise<MessageAPIResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/refresh',
      path: {
        account_name: accountName,
        message_id: messageId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        400: `Bad request. Verify that the message contains a table ID.`,
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Query Message Table Data
   * Get the table data associated with a message.
   *
   * This will return an error if the message does not contain a table ID.
   * @param accountName
   * @param messageId The id of a message in a chat.
   * @param limit The maximum number of rows to include in the response.
   * @param skip The number of rows to skip. Equivalent of 'skip' in database queries.
   * @param sort The columns to sort by. Prefix with '-' for descending order.
   * @param sessionId
   * @returns DataPage Successful Response
   * @throws ApiError
   */
  public readQueryMessageTableData(
    accountName: string,
    messageId: string,
    limit: number = 100,
    skip?: number,
    sort?: Array<string> | null,
    sessionId?: string | null,
  ): CancelablePromise<DataPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/data',
      path: {
        account_name: accountName,
        message_id: messageId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        skip: skip,
        sort: sort,
      },
      errors: {
        400: `Bad request. Verify that the message contains a table ID.`,
        404: `The item could not be found.`,
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Query Message Table Data As Csv
   * Read message table data as a CSV.
   *
   * This will return an error if the message does not contain a table ID.
   * @param accountName
   * @param messageId The id of a message in a chat.
   * @param sessionId
   * @returns any The table data as a CSV file.
   * @throws ApiError
   */
  public readQueryMessageTableDataAsCsv(
    accountName: string,
    messageId: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/data/csv',
      path: {
        account_name: accountName,
        message_id: messageId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        400: `Bad request. Verify that the message contains a table ID.`,
        404: `The item could not be found.`,
        422: `Validation Error`,
      },
    })
  }
}
