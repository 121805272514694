// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/Combination.ts"
);
import.meta.hot.lastModified = "1731736109643.109";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Combination Strategies for Filter Conditions.
 */
export enum Combination {
  ALL = 'ALL',
  ANY = 'ANY',
}
