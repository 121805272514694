// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/logging/devConsole.ts"
);
import.meta.hot.lastModified = "1726176605619.3848";
}
// REMIX HMR END

import { type KeysWithValuesOfType } from '@/types'

type ConsoleFunctions = KeysWithValuesOfType<
  Console,
  (...args: unknown[]) => unknown
>

export function devConsole<Type extends ConsoleFunctions>(
  type: Type,
  ...args: Parameters<Console[Type]>
) {
  if (
    (typeof document !== 'undefined' &&
      ['localhost', 'arctic', 'atlantic'].some((url) =>
        window.location.host.startsWith(url),
      )) ||
    typeof document === 'undefined'
  ) {
    // eslint-disable-next-line no-console
    console[type](...args)
  }
}
