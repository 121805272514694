// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/ZenMeasureType.ts"
);
import.meta.hot.lastModified = "1731736109727.1084";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Possible ZenMeasure types.
 */
export enum ZenMeasureType {
  SUM = 'sum',
  AVERAGE = 'average',
  COUNT = 'count',
  COUNT_DISTINCT = 'count_distinct',
  SUM_DISTINCT = 'sum_distinct',
  AVERAGE_DISTINCT = 'average_distinct',
  CUMULATIVE = 'cumulative',
  MEDIAN = 'median',
  MAX = 'max',
  MIN = 'min',
  NUMBER = 'number',
}
