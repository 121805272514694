/**
 * Hashes a string to a positive integer.
 *
 * @param name
 * @returns Positive integer
 */
export function hash(input: string) {
  let num = 0
  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i)
    // eslint-disable-next-line no-bitwise
    num = (num << 5) - num + char
    // eslint-disable-next-line no-bitwise
    num |= 0 // Convert to 32bit integer
  }
  return Math.abs(num)
}
