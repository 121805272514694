// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils.ts"
);
import.meta.hot.lastModified = "1731712816413.9895";
}
// REMIX HMR END

import {
  useActionData,
  useMatches,
  useParams,
  useRouteLoaderData,
  useLocation,
} from '@remix-run/react'
import type { SerializeFrom } from '@remix-run/server-runtime'
import type { Dispatch, SetStateAction } from 'react'
import {
  useCallback,
  useEffect,
  useLayoutEffect as useReactLayoutEffect,
  useState,
} from 'react'
import colors from 'tailwindcss/colors.js'

import { type Env, type Messages, type Primitive } from '@/types'

import { useToast } from '~/ui/atoms/Toast'

import { type LoaderData as AccountLoaderData } from '~/routes/$accountName'

import {
  type Account,
  type ClientSafeAccountSettings,
  type ErrorContent,
  type User,
} from '~/api'
import type { LoaderData as RootLoaderData } from '~/root'
import { invariant } from '~/utils/invariant'
import { type RouteActionData } from '~/utils/network'

import { errorToString } from '../lib/utils/errorHandling'

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
)

export const useLayoutEffect = canUseDOM ? useReactLayoutEffect : useEffect

export type RouteResponse<T> = SuccessfulRouteResponse<T> | ErrorRouteResponse
export type RouteError = ErrorContent & { requestId: string }
export type SuccessfulRouteResponse<T> = { response: T; error: null }
export type ErrorRouteResponse = {
  response: null
  error: RouteError
}

export function useIsChats() {
  const location = useLocation()
  const accountSettings = useAccountSettings()
  let isChats = accountSettings.is_chats_default?.value === true
  if (
    location.pathname.includes('conversations') ||
    location.pathname.includes('featured')
  ) {
    isChats = false
  } else if (location.pathname.includes('chats')) {
    isChats = true
  }
  return isChats
}

/**
 * Capitalizes the first letter of each word in the given string. Lowercases all
 * the other letters in each string (e.g. "RESORT 2024" -> "Resort 2024").
 * @param str The string to capitalize.
 * @returns The capitalized string.
 */
export function caps(sentence: string): string {
  return sentence
    .split(' ')
    .map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1).toLowerCase()}`)
    .join(' ')
}

/**
 * Use the metric or dimension business-friendly description if available.
 * @param name The metric or dimension name.
 * @returns The business-friendly name.
 */
export function getDisplayName(name: string) {
  return caps(name.replace(/_/g, ' '))
}

/**
 * Infers a user's name given their email address. Useful for signup forms.
 * @param email - the email address to infer a name from.
 * @returns the inferred name string, properly title-cased.
 */
export function inferNameFromEmail(email: string): string {
  const nameMatch = /^([^@]*)@/.exec(email)
  const nameStr = nameMatch ? nameMatch[1] : ''
  return nameStr
    .replace(/[_.]/g, ' ')
    .replace(/(\b[a-z](?!\s))/g, (s) => s.toUpperCase())
}

/**
 * Convenience hook to get the account name from the path parameters and ensure
 * that it is not falsy. Alternative to `useAccount().name`.
 * @returns the account name.
 * @throws if there is no account name in the path parameters.
 */
export function useAccountName(): string {
  const { accountName } = useParams()
  invariant(accountName, 'accountName is required')
  return accountName
}

/**
 * A wrapper around the useActionData hook that handles RouteResponses and can
 * optionally surface errors from those RouteResponse objects.
 *
 * @see {@link https://remix.run/docs/en/main/hooks/use-action-data}
 * @see {@link https://reactrouter.com/en/6.9.0/hooks/use-action-data}
 * @returns the action data (which is a RouteResponse).
 */
export function useRouteActionData<T>(
  messages: Messages = {},
): RouteActionData<T> {
  const data = useActionData<RouteResponse<T>>()
  const { toast } = useToast()
  useEffect(() => {
    if (
      messages.success !== undefined &&
      data !== undefined &&
      'response' in data &&
      data.response !== null
    )
      toast({
        variant: 'success',
        description: messages.success,
      })
    if (
      messages.error !== undefined &&
      data !== undefined &&
      'error' in data &&
      data.error !== null
    )
      toast({
        variant: 'error',
        title: messages.error,
        description: errorToString(data.error),
      })
  }, [data, messages.error, messages.success, toast])
  return data
}

/**
 * Serialize an object into a Record<string, string> that can be sent as form
 * data (e.g. when using Remix `useFetcher` APIs) back to server-side functions.
 * @param obj - the object to serialize.
 * @returns a Record<string, string> that can be sent as form data.
 */
export function serialize<T extends Record<string, unknown>>(
  obj: T,
): Record<string, string> {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => {
      acc[key] = JSON.stringify(value)
      return acc
    },
    {} as Record<string, string>,
  )
}

/**
 * Deserialize a Record<string, string> that was sent as form data (e.g. when
 * using Remix `useFetcher` APIs) back to server-side functions.
 * @param obj - the object to deserialize.
 * @returns a Record<string, unknown> that was sent as form data.
 */
function deserializeObject<T extends Record<string, unknown>>(
  obj: Record<string, string>,
): T {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    acc[key as keyof T] = JSON.parse(value) as T[keyof T]
    return acc
  }, {} as T)
}

function isStringFormData(
  obj: Record<string, FormDataEntryValue>,
): obj is Record<string, string> {
  return Object.values(obj).every((value) => typeof value === 'string')
}

/**
 * Deserialize a FormData object that was serialized using `serialize`.
 * @param formData - the FormData object to deserialize.
 * @returns a Record<string, unknown> that was sent as form data.
 */
export function deserializeFormData<T extends Record<string, unknown>>(
  formData?: FormData,
): T {
  if (!formData) return {} as T
  const obj = Object.fromEntries(formData.entries())
  if (!isStringFormData(obj))
    throw new Error(
      'Expected to find only string values while deserializing form data. ' +
        'You may have tried to deserialize form data that was not serialized ' +
        'properly. Make sure to call "serialize" client-side before sending ' +
        'form data.',
    )
  return deserializeObject<T>(obj)
}

/**
 * The useState React hook is great for state that should be freshly initialized
 * on every visit, but what about for state that should be persisted between
 * sessions?
 *
 * A good example of this is filters. If I set a filter to sort based on price
 * instead of newest items, that value should "stick", so that if I come back to
 * this site in a week, it remembers that I want to sort by price.
 *
 * The useStickyState hook works just like useState, except it backs up to (and
 * restores from) localStorage.
 *
 * Note that you should be wary of using this hook. Generally, state that needs
 * to be persisted between sessions should be stored in the back-end database.
 *
 * @see {@link https://joshwcomeau.com/snippets/react-hooks/use-sticky-state}
 * @see {@link https://joshwcomeau.com/react/persisting-react-state-in-localstorage}
 *
 * @param defaultValue - the default state value.
 * @param key - the unique key to be used when storing in `localStorage`.
 * @returns the same signature as `useState`.
 */
export function useStickyState<T>(
  defaultValue: T,
  key: string,
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() => {
    if (!canUseDOM) return defaultValue
    const stickyValue = window?.localStorage?.getItem(key)
    return stickyValue !== null ? (JSON.parse(stickyValue) as T) : defaultValue
  })
  useEffect(() => {
    if (canUseDOM) window.localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])
  return [value, setValue]
}

function queryParamsFromUrl() {
  const urlObj = new URL(window.location.href)
  return Object.fromEntries(urlObj.searchParams)
}

function updateUrlWithoutNav(newUrl: string) {
  window.history.replaceState({}, document.title, newUrl)
}

function updateQueryParamWithoutNav(key: string, value: string) {
  if (!canUseDOM) return
  const queryParams = queryParamsFromUrl()
  queryParams[key] = value
  const newParams = new URLSearchParams()
  Object.keys(queryParams).forEach((curr: string) => {
    newParams.append(curr, queryParams[curr])
  })

  updateUrlWithoutNav(
    `${window.location.pathname}?${newParams.toString()}${
      window.location.hash
    }`,
  )
}

/**
 * @param defaultValue initial value to be set within the URL
 * @param key query parameter key to be used to store the value
 * @param fromString an optional function to convert the stored query param string value back into the desired type.
 * If not provided, this will just return a string
 * @returns
 */
export function useQueryState<T extends Exclude<Primitive, symbol>>(
  defaultValue: T,
  key: string,
  fromString: (str: string) => T = (str) => str as T,
) {
  const [value, setValue] = useState<T>(() => {
    if (!canUseDOM) return defaultValue
    const queryParams = queryParamsFromUrl()
    const queryValue = queryParams[key]
    const currValue = fromString(queryValue) ?? defaultValue
    if (currValue != null) {
      updateQueryParamWithoutNav(key, currValue.toString())
    }
    return currValue
  })

  const setQueryState = useCallback(
    (newValue: T) => {
      setValue(newValue)
      if (typeof document !== 'undefined') {
        updateQueryParamWithoutNav(key, `${newValue}`)
      }
    },
    [key],
  )

  return [value, setQueryState] as const
}

/**
 * This base hook is used in other hooks to quickly search for specific data
 * across all loader data using useMatches.
 * @param id - the route ID to get the loader data from.
 * @returns the router data or undefined if not found.
 * @see {@link https://discord.com/channels/770287896669978684/770287896669978687/1085525726826090517}
 * @example
 * ```ts
 * import type { loader } from '~/routes/$accountName/products'
 *
 * const products = useMatchesData<typeof loader>('$accountName/products')
 * ```
 */
function useMatchesData<T = unknown>(id: string): SerializeFrom<T> | undefined {
  return useRouteLoaderData(id)
}

export function useRouteId(): string {
  const matches = useMatches()
  const routeId = matches.at(-1)?.id
  invariant(routeId, 'No route ID found.')
  return routeId
}

// TODO make this type guard better and move it to where the user interface is.
function isUser(user: unknown): user is User {
  return (
    !!user &&
    typeof user === 'object' &&
    typeof (user as User).email === 'string'
  )
}

export function useEnv(): Env | null {
  const data = useMatchesData<RootLoaderData>('root')
  return data?.env ?? null
}

export function useOptionalUser(): User | undefined {
  const data = useMatchesData<RootLoaderData>('root')
  return data && isUser(data.user) ? data.user : undefined
}

export function useUser(): User {
  const maybeUser = useOptionalUser()
  const error =
    'No user found in root loader, but user is required by useUser. If user ' +
    'is optional, try useOptionalUser instead.'
  invariant(maybeUser, error)
  return maybeUser
}

export function useIsSuperuser() {
  const user = useUser()
  return user.is_superuser
}

export function useIsAdmin() {
  const user = useUser()
  const account = useAccount()

  const adminForAccountNames = user.is_admin_for?.map((acct) => acct.name)
  return useIsSuperuser() || adminForAccountNames?.includes(account.name)
}

export function useIsEvalMaintainer() {
  const user = useUser()
  const account = useAccount()
  return (
    useIsSuperuser() ||
    user.is_eval_maintainer_for?.some((a) => a.name === account.name)
  )
}

export function useAccount(): Account {
  const layoutData = useMatchesData<AccountLoaderData>(
    'routes/$accountName',
  )?.account
  const widgetData = useMatchesData<AccountLoaderData>(
    'routes/widget.$accountName.($conversationId)',
  )?.account
  const maybeAccount = layoutData ?? widgetData
  const error =
    'No account found in account loader. Did you try calling useAccount in a ' +
    'route not nested underneath /$accountName?'
  invariant(maybeAccount, error)
  return maybeAccount
}

const DEFAULT_COLORS = [
  colors.indigo['500'],
  colors.purple['500'],
  colors.pink['500'],
  colors.red['500'],
  colors.amber['500'],
  colors.lime['500'],
  colors.emerald['500'],
  colors.cyan['500'],
  colors.blue['500'],
  colors.violet['500'],
  colors.fuchsia['500'],
  colors.rose['500'],
  colors.orange['500'],
  colors.yellow['500'],
  colors.green['500'],
  colors.teal['500'],
  colors.sky['500'],
]

export function useColors(): string[] {
  return useAccount().colors ?? DEFAULT_COLORS
}

export function useAccountSettings(): ClientSafeAccountSettings {
  const layoutData = useMatchesData<AccountLoaderData>(
    'routes/$accountName',
  )?.accountSettings
  const widgetData = useMatchesData<AccountLoaderData>(
    'routes/widget.$accountName.($conversationId)',
  )?.accountSettings
  const accountSettings = layoutData ?? widgetData
  const error =
    'No account settings found in account loader. Did you try calling ' +
    'useAccountSettings in a route not nested underneath /$accountName?'
  invariant(accountSettings, error)
  return accountSettings
}
