import { z } from 'zod'

import { TaskName, TaskStatus, type Task, type TaskMeta } from '~/api'

import { errorContent } from './errorContent'

export const taskMeta: z.ZodType<TaskMeta> = z.object({
  progress_current: z.number().optional(),
  progress_total: z.number().optional(),
  num_results: z.number().nullable().optional(),
  error: errorContent.nullable().optional(),
  retrieval_ready: z.boolean().nullable().optional(),
  first_query_ready: z.boolean().nullable().optional(),
  second_query_ready: z.boolean().nullable().optional(),
  table_result_ready: z.boolean().nullable().optional(),
  sql_timed_out: z.boolean().nullable().optional(),
})

export const task: z.ZodType<Task> = z.object({
  id: z.string().nullable().optional(),
  account_id: z.string().nullable().optional(),
  project_id: z.number().nullable().optional(),
  pipeline_id: z.string().nullable().optional(),
  is_scheduled: z.boolean().nullable().optional(),
  node_id: z.string().nullable().optional(),
  cell_id: z.string().nullable().optional(),
  conversation_id: z.string().nullable().optional(),
  ssds_id: z.string().nullable().optional(),
  name: z.nativeEnum(TaskName).optional(),
  uri: z.string().nullable().optional(),
  meta: taskMeta.nullable().optional(),
  status: z.nativeEnum(TaskStatus),
  completed_at: z.string().nullable().optional(),
  created_at: z.string(),
  updated_at: z.string(),
  duration_ms: z.number().nullable().optional(),
})
