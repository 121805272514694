// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/FilterOperator.ts"
);
import.meta.hot.lastModified = "1731736109667.1086";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Filter operator.
 */
export enum FilterOperator {
  EQUALS = 'equals',
  NOT_EQUALS = 'not_equals',
  CASE_INSENSITIVE_EQUALS = 'case-insensitive_equals',
  NOT_CASE_INSENSITIVE_EQUALS = 'not_case-insensitive_equals',
  GREATER_THAN = 'greater_than',
  GREATER_THAN_OR_EQUAL_TO = 'greater_than_or_equal_to',
  LESS_THAN = 'less_than',
  LESS_THAN_OR_EQUAL_TO = 'less_than_or_equal_to',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'not_contains',
  STARTS_WITH = 'starts_with',
  NOT_STARTS_WITH = 'not_starts_with',
  ENDS_WITH = 'ends_with',
  NOT_ENDS_WITH = 'not_ends_with',
  BLANK = 'blank',
  NOT_BLANK = 'not_blank',
  IN = 'in',
  NOT_IN = 'not_in',
  BETWEEN = 'between',
  NOT_BETWEEN = 'not_between',
}
