// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/DatabricksService.ts"
);
import.meta.hot.lastModified = "1731736112819.0906";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DatabricksConnection } from '../models/DatabricksConnection'
import type { DatabricksConnectionCreate } from '../models/DatabricksConnectionCreate'
import type { DatabricksConnectionUpdate } from '../models/DatabricksConnectionUpdate'
import type { TableColumn } from '../models/TableColumn'

import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'

export class DatabricksService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create Databricks Connection
   * Create new Databricks connection.
   * @param accountName
   * @param requestBody
   * @param validate
   * @param sessionId
   * @returns DatabricksConnection Successful Response
   * @throws ApiError
   */
  public createDatabricksConnection(
    accountName: string,
    requestBody: DatabricksConnectionCreate,
    validate: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<DatabricksConnection> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/databricks/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        validate: validate,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Validate Databricks Connection
   * Validate Databricks connection.
   * @param accountName
   * @param databricksConnectionId
   * @returns DatabricksConnection Successful Response
   * @throws ApiError
   */
  public validateDatabricksConnection(
    accountName: string,
    databricksConnectionId: string,
  ): CancelablePromise<DatabricksConnection> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/databricks/{databricks_connection_id}/validate',
      path: {
        account_name: accountName,
        databricks_connection_id: databricksConnectionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Databricks Connection
   * Read Databricks connection.
   * @param accountName
   * @param databricksConnectionId
   * @param sessionId
   * @returns DatabricksConnection Successful Response
   * @throws ApiError
   */
  public readDatabricksConnection(
    accountName: string,
    databricksConnectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<DatabricksConnection> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/databricks/{databricks_connection_id}',
      path: {
        account_name: accountName,
        databricks_connection_id: databricksConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Databricks Connection
   * Update Databricks connection.
   * @param accountName
   * @param databricksConnectionId
   * @param requestBody
   * @param sessionId
   * @returns DatabricksConnection Successful Response
   * @throws ApiError
   */
  public updateDatabricksConnection(
    accountName: string,
    databricksConnectionId: string,
    requestBody: DatabricksConnectionUpdate,
    sessionId?: string | null,
  ): CancelablePromise<DatabricksConnection> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/connections/databricks/{databricks_connection_id}',
      path: {
        account_name: accountName,
        databricks_connection_id: databricksConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Databricks Connection
   * Delete Databricks connection.
   * @param accountName
   * @param databricksConnectionId
   * @param sessionId
   * @returns DatabricksConnection Successful Response
   * @throws ApiError
   */
  public deleteDatabricksConnection(
    accountName: string,
    databricksConnectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<DatabricksConnection> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/connections/databricks/{databricks_connection_id}',
      path: {
        account_name: accountName,
        databricks_connection_id: databricksConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Databricks Connection Databases
   * Read Databricks databases.
   * @param accountName
   * @param databricksConnectionId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readDatabricksConnectionDatabases(
    accountName: string,
    databricksConnectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/databricks/{databricks_connection_id}/databases',
      path: {
        account_name: accountName,
        databricks_connection_id: databricksConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Databricks Connection Schemas
   * Read Databricks schemas.
   * @param accountName
   * @param databricksConnectionId
   * @param database
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readDatabricksConnectionSchemas(
    accountName: string,
    databricksConnectionId: string,
    database: string,
    sessionId?: string | null,
  ): CancelablePromise<Record<string, Array<string>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/databricks/{databricks_connection_id}/schemas',
      path: {
        account_name: accountName,
        databricks_connection_id: databricksConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        database: database,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Databricks Connection Tables
   * Read Databricks tables.
   * @param accountName
   * @param databricksConnectionId
   * @param database
   * @param schema
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readDatabricksConnectionTables(
    accountName: string,
    databricksConnectionId: string,
    database: string,
    schema?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<Record<string, Record<string, Array<string>>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/databricks/{databricks_connection_id}/tables',
      path: {
        account_name: accountName,
        databricks_connection_id: databricksConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        database: database,
        schema: schema,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Databricks Connection Columns
   * Read Databricks table columns.
   * @param accountName
   * @param databricksConnectionId
   * @param schema
   * @param table
   * @param sessionId
   * @returns TableColumn Successful Response
   * @throws ApiError
   */
  public readDatabricksConnectionColumns(
    accountName: string,
    databricksConnectionId: string,
    schema: string,
    table: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<TableColumn>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/databricks/{databricks_connection_id}/columns',
      path: {
        account_name: accountName,
        databricks_connection_id: databricksConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        schema: schema,
        table: table,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
