// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/MessagesV2Service.ts"
);
import.meta.hot.lastModified = "1731736112919.0898";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessageAPIGetResponse } from '../models/MessageAPIGetResponse'
import type { MessagePayloadType } from '../models/MessagePayloadType'
import type { NegativeFeedbackAPISend } from '../models/NegativeFeedbackAPISend'

import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'

export class MessagesV2Service {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Read Message
   * Read a message.
   *
   * Get the message including the contents of its payload. This endpoint is needed for
   * reading the contents of messages after their tasks finish and messages that would
   * cause performance issues for the backend to return in batches. Note that this endpoint
   * accepts different params that will be used/ignored depending on payload type. Each
   * parameter will apply only to one payload type.
   * @param accountName The name of the organization or account.
   * @param messageId
   * @param acceptedPayloadTypes
   * @param tableLimit
   * @param tableOffset
   * @returns MessageAPIGetResponse Successful Response
   * @throws ApiError
   */
  public readMessage(
    accountName: string,
    messageId: string,
    acceptedPayloadTypes?: Array<MessagePayloadType> | null,
    tableLimit: number = 20,
    tableOffset?: number,
  ): CancelablePromise<MessageAPIGetResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/orgs/{account_name}/messages/{message_id}',
      path: {
        account_name: accountName,
        message_id: messageId,
      },
      query: {
        accepted_payload_types: acceptedPayloadTypes,
        table_limit: tableLimit,
        table_offset: tableOffset,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Feedback
   * Create negative feedback for a message.
   *
   * Note that feedback is shared amongst all messages in the AI response. Providing
   * feedback for on message will affect all messages that share the same `responds_to`.
   * @param accountName The name of the organization or account.
   * @param messageId
   * @param requestBody
   * @returns MessageAPIGetResponse Successful Response
   * @throws ApiError
   */
  public createFeedback(
    accountName: string,
    messageId: string,
    requestBody: NegativeFeedbackAPISend,
  ): CancelablePromise<MessageAPIGetResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/orgs/{account_name}/messages/{message_id}/feedback',
      path: {
        account_name: accountName,
        message_id: messageId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Remove Feedback
   * Remove feedback for a message.
   * @param accountName The name of the organization or account.
   * @param messageId
   * @returns MessageAPIGetResponse Successful Response
   * @throws ApiError
   */
  public removeFeedback(
    accountName: string,
    messageId: string,
  ): CancelablePromise<MessageAPIGetResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v2/orgs/{account_name}/messages/{message_id}/feedback',
      path: {
        account_name: accountName,
        message_id: messageId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
