// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/IdentifierJoinType.ts"
);
import.meta.hot.lastModified = "1731736109671.1086";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Possible join types.
 *
 * https://docs.zenlytic.com/docs/data_modeling/join#properties
 */
export enum IdentifierJoinType {
  LEFT_OUTER = 'left_outer',
  INNER = 'inner',
  FULL_OUTER = 'full_outer',
  CROSS = 'cross',
}
