// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/CellOrderBy.ts"
);
import.meta.hot.lastModified = "1731736109635.109";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Sortable Columns in BumblebeeCell
 */
export enum CellOrderBy {
  ID = 'id',
  CREATED_AT = 'created_at',
  CREATOR_ID = 'creator_id',
  UPDATED_AT = 'updated_at',
  USER_QUESTION = 'user_question',
}
