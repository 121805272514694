// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/RetrievalService.ts"
);
import.meta.hot.lastModified = "1731736113079.089";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RetrievalParams } from '../models/RetrievalParams'

import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'

export class RetrievalService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Retrieve
   * Retrieve columns, dimensions, measures and top values.
   *
   * Endpoint used for evaluation purposes only.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param question
   * @param sessionId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public retrieve(
    accountName: string,
    starSchemaDataSourceId: string,
    question: string,
    sessionId?: string | null,
    requestBody?: RetrievalParams | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/retrieve',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        question: question,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Retrieve Ner
   * Retrieve filter values from a question.
   * @param question
   * @param accountName
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public retrieveNer(
    question: string,
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/retrieve/ner',
      cookies: {
        session_id: sessionId,
      },
      query: {
        question: question,
        account_name: accountName,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Retrieve Text Search
   * Retrieve sample values using text search.
   * @param starSchemaDataSourceId
   * @param question
   * @param accountName
   * @param numValuesToRetrieve
   * @param useDmGroup
   * @param explainAnalyze
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public retrieveTextSearch(
    starSchemaDataSourceId: string,
    question: string,
    accountName: string,
    numValuesToRetrieve: number = 5,
    useDmGroup: boolean = false,
    explainAnalyze: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/star_schema_data_source/{star_schema_data_source_id}/retrieve/text',
      path: {
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        question: question,
        num_values_to_retrieve: numValuesToRetrieve,
        use_dm_group: useDmGroup,
        explain_analyze: explainAnalyze,
        account_name: accountName,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Retrieve Embedding Search
   * Retrieve sample values using embedding search.
   * @param starSchemaDataSourceId
   * @param question
   * @param accountName
   * @param numValuesToRetrieve
   * @param useDmGroup
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public retrieveEmbeddingSearch(
    starSchemaDataSourceId: string,
    question: string,
    accountName: string,
    numValuesToRetrieve: number = 5,
    useDmGroup: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/star_schema_data_source/{star_schema_data_source_id}/retrieve/embedding',
      path: {
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        question: question,
        num_values_to_retrieve: numValuesToRetrieve,
        use_dm_group: useDmGroup,
        account_name: accountName,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
