// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/FieldType.ts"
);
import.meta.hot.lastModified = "1731736109667.1086";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumerates the dimension and metric literals.
 */
export enum FieldType {
  DIMENSION = 'dimension',
  METRIC = 'metric',
}
