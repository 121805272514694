import { schemas } from '@/schemas'

import { type ErrorInfo, type ErrorContent } from '~/api'

export function isErrorContent(obj: unknown): obj is ErrorContent {
  return schemas.errorContent.safeParse(obj).success
}

export function isErrorInfo(obj: unknown): obj is ErrorInfo {
  return schemas.errorInfo.safeParse(obj).success
}
