import { type NullableTask } from '@/types'

import { TaskStatus } from '~/api'

export function isTaskRunning(task: NullableTask) {
  return (
    task?.status === TaskStatus.PENDING || task?.status === TaskStatus.PROGRESS
  )
}

export function isTaskComplete(task: NullableTask) {
  return (
    task?.status === TaskStatus.SUCCESS || task?.status === TaskStatus.FAILURE
  )
}
