// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/MetricFilter.ts"
);
import.meta.hot.lastModified = "1731736111759.0967";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Combination } from './Combination'
import type { FilterOrigin } from './FilterOrigin'
import type { MetricFilterCondition } from './MetricFilterCondition'

/**
 * Filter operating over metrics.
 */
export type MetricFilter = {
  id?: string | null
  query_id?: string | null
  name?: string | null
  combination?: Combination
  description?: string | null
  filter_origin?: FilterOrigin
  is_enabled?: boolean
  field_type?: MetricFilter.field_type
  conditions: Array<MetricFilterCondition>
}

export namespace MetricFilter {
  export enum field_type {
    METRIC = 'metric',
  }
}
