// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/DatasetsV2Service.ts"
);
import.meta.hot.lastModified = "1731736112847.0903";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'

export class DatasetsV2Service {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Suggested Prompts
   * Get suggested prompts for a star schema data source.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param numberSuggestions
   * @returns string Successful Response
   * @throws ApiError
   */
  public getSuggestedPrompts(
    accountName: string,
    starSchemaDataSourceId: string,
    numberSuggestions: number = 5,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/orgs/{account_name}/datasets/{star_schema_data_source_id}/suggestions',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      query: {
        number_suggestions: numberSuggestions,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
