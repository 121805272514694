import { z } from 'zod'

import {
  CellState,
  type BumblebeeCellAPIUpdate,
  type BumblebeeCellBasic,
  type MoonlightStreamedOutput,
  type MoonlightStreamedOutputQuestion,
  type Permissions,
} from '~/api'

export const moonlightStreamedOutputQuestion: z.ZodType<MoonlightStreamedOutputQuestion> =
  z.object({
    question: z.string(),
    why: z.string(),
    dimensions: z.string(),
    metrics: z.string(),
    filters: z.string(),
  })

export const moonlightStreamedOutput: z.ZodType<MoonlightStreamedOutput> =
  z.object({
    intro: z.string(),
    questions: z.array(moonlightStreamedOutputQuestion),
    outro: z.string(),
  })

export const permissions: z.ZodType<Permissions> = z.object({
  read: z.boolean(),
  write: z.boolean(),
})

export const bumblebeeCellBasic: z.ZodType<BumblebeeCellBasic> = z.object({
  creator_id: z.string(),
  permissions: permissions.optional(),
  conversation_id: z.string(),
  user_question: z.string(),
  updated_question: z.string().nullable(),
  index: z.number().nullable().optional(),
  sql: z.string().nullable().optional(),
  name: z.string().nullable().optional(),
  bb_model: z.string().nullable().optional(),
  semantic_query_id: z.string().nullable().optional(),
  ssds_id: z.string().nullable().optional(),
  // TODO: improve types here
  retrieved_metrics: z.array(z.any()).optional(),
  // TODO: improve types here
  retrieved_dimensions: z.array(z.any()).optional(),
  // TODO: improve types here
  is_answerable: z.any(),
  is_answerable_explanation: z.string().nullable().optional(),
  is_holdout: z.boolean().nullable().optional(),
  // TODO: improve types here
  bb_example: z.any().nullable().optional(),
  has_feedback: z.boolean(),
  copied_from: z.string().nullable().optional(),
  moonlight_response: moonlightStreamedOutput.nullable(),
  moonlight_prompt: z.string().nullable().optional(),
  id: z.string(),
  account_id: z.string(),
  is_stale: z.boolean(),
  // TODO: improve types here
  state: z.any().nullable().optional(),
  created_at: z.string(),
  updated_at: z.string(),
  // TODO: improve types here
  creator: z.any().nullable().optional(),
  // TODO: improve types here
  feedback: z.any().nullable().optional(),
})

export const bumblebeeCellAPIUpdate: z.ZodType<BumblebeeCellAPIUpdate> =
  z.object({
    user_question: z.string().nullable().optional(),
    updated_question: z.string().nullable().optional(),
    name: z.string().nullable().optional(),
    ssds_id: z.string().nullable().optional(),
    semantic_query_id: z.string().nullable().optional(),
    state: z.nativeEnum(CellState).nullable().optional(),
  })
