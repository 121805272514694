import { z } from 'zod'

import { type User } from '~/api'

import { account } from './account'

export const user: z.ZodType<User> = z.object({
  name: z.string(),
  email: z.string().email(),
  avatar: z.string().nullable().optional(),
  is_superuser: z.boolean(),
  id: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  accounts: z.array(account),
  is_admin_for: z.array(account),
  is_eval_maintainer_for: z.array(account),
})
