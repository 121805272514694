// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/invariant.ts"
);
import.meta.hot.lastModified = "1727903524566.7463";
}
// REMIX HMR END

import { json } from '@remix-run/node'

import { logger } from '~/logging/log.client'
import { log } from '~/logging/log.server'

/**
 * @param condition The value you expect to be truthy
 * @param message The error message to display if the condition is falsy
 * @param statusCode Optional HTTP status code if you want to throw a response type from the server resulting
 * in a CatchBoundary being hit. If you do not supply a status node, or if you are on the client side this value
 * will not be used, and a standard Error will be thrown resulting in an ErrorBoundary being hit
 */
// TODO: use shared invairant instead, and handle logging conditionally or somewhere else
export function invariant(
  condition: unknown,
  message: string = 'An invariant failed.',
  statusCode?: number,
): asserts condition {
  if (!condition) {
    const errorMessage = `Invariant failed${
      statusCode ? ` with status code ${statusCode}` : ''
    }: ${message}`
    if (typeof document === 'undefined') {
      log.error(errorMessage)
    } else {
      logger.error(errorMessage)
    }
    if (typeof document === 'undefined' && statusCode != null) {
      // Since we get the TS benefit from the StatusCode type imported here,
      // but we aren't using an error object here, we don't get any additional
      // benefit from that function, and it is causing dependency cycle errors.
      // So, I'm changing this to use throw json() to avoid that.
      throw json(message, statusCode)
    } else {
      // we're on the cient-side, or a statusCode wasn't provided so throw regular error
      // TODO: do something else to handle errors here? This just results in an error log in the console
      throw new Error(message)
    }
  }
}
