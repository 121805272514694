import { proxy } from 'valtio'
import { devtools } from 'valtio/utils'

import { type Nullish } from '@/types'

import { type ClientSafeAccountSettings, type Account, type User } from '~/api'

interface UserStore {
  user: Nullish<User>
  account: Nullish<Account>
  accountSettings: Nullish<ClientSafeAccountSettings>
}

export const userStore: UserStore = proxy({
  user: null,
  account: null,
  accountSettings: null,
})

devtools(userStore, { name: 'userStore', enabled: true })
