// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/RenderType.ts"
);
import.meta.hot.lastModified = "1731736109695.1084";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enum for the render type of the message
 *
 * These types are used to guide the client on how to render the message.
 */
export enum RenderType {
  NONE = 'NONE',
  TEMPORARY = 'TEMPORARY',
  SOFT = 'SOFT',
  STANDARD = 'STANDARD',
}
