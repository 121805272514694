import * as accountSchemas from './account'
import * as cellSchemas from './cell'
import * as connectionSchemas from './connection'
import * as conversationSchemas from './conversation'
import * as datasetSchemas from './datasets'
import * as errorContentSchemas from './errorContent'
import * as taskSchemas from './task'
import * as userSchemas from './user'

export const schemas = {
  ...accountSchemas,
  ...cellSchemas,
  ...connectionSchemas,
  ...conversationSchemas,
  ...datasetSchemas,
  ...errorContentSchemas,
  ...taskSchemas,
  ...userSchemas,
}
