// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/MessagePayloadType.ts"
);
import.meta.hot.lastModified = "1731736109675.1086";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The type of payload that can be sent in a message.
 *
 * Attributes:
 * TEXT: A text message.
 * SEMANTIC_QUERY: A semantic query.
 * TABLE: A table of data.
 * ERROR: An error message.
 * PROMPT_OPTIONS: A message with prompt options.
 */
export enum MessagePayloadType {
  TEXT = 'TEXT',
  SEMANTIC_QUERY = 'SEMANTIC_QUERY',
  TABLE = 'TABLE',
  ERROR = 'ERROR',
  PROMPT_OPTIONS = 'PROMPT_OPTIONS',
}
