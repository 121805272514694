import { z } from 'zod'

import { type ErrorContent, type ErrorInfo } from '~/api'

export const errorInfo: z.ZodType<ErrorInfo> = z.object({
  code: z.string(),
  description: z.string(),
  field: z.string().nullable().optional(),
  line_no: z.number().nullable().optional(),
  line_pos: z.number().nullable().optional(),
})

export const errorContent: z.ZodType<ErrorContent> = z.object({
  detail: z.string(),
  status_code: z.number(),
  errors: z.array(errorInfo),
  warnings: z.array(errorInfo),
  error_name: z.string(),
})
