import { z } from 'zod'

import { type ConversationBasic, type ConversationFull } from '~/api'

import { bumblebeeCellBasic, permissions } from './cell'
import { task } from './task'
import { user } from './user'

const conversationBasicSchema = z.object({
  creator_id: z.string().nullable().optional(),
  permissions: permissions.optional(),
  name: z.string().nullable().optional(),
  description: z.string().nullable().optional(),
  account_id: z.string().nullable().optional(),
  is_pinned: z.boolean().nullable().optional(),
  is_hidden: z.boolean().nullable().optional(),
  is_moonlight: z.boolean().nullable().optional(),
  id: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  creator: user.nullable().optional(),
  is_starred: z.boolean().nullable().optional(),
  bumblebee_cells_count: z.number().nullable().optional(),
})

export const conversationBasic: z.ZodType<ConversationBasic> =
  conversationBasicSchema

export const conversationFull: z.ZodType<ConversationFull> =
  conversationBasicSchema.omit({ bumblebee_cells_count: true }).extend({
    bumblebee_cells: z.array(bumblebeeCellBasic),
    tasks: z.array(task).optional(),
  })
