import { isRouteErrorResponse } from '@remix-run/react'
import { useEffect, useState } from 'react'
import { ZodError } from 'zod'

import { isErrorContent } from '@/guards'

import { type ErrorContent } from '~/api'

/**
 * Given an ErrorContent object, converts it into a user-readable string. This
 * is handled in the front-end to enable fancy error displays in the future
 * (e.g. placing each error in the errors array into its own box, etc).
 * @param error - the ErrorContent object to stringify.
 * @returns a user-readable string.
 */
export function errorToString(error: ErrorContent): string {
  return [error.detail, ...error.errors.map((e) => e.description)].join('\n')
}

export const unknownErrorContent: ErrorContent = {
  detail: 'An unexpected error occurred.',
  error_name: 'NSUnexpectedError',
  status_code: 500,
  warnings: [],
  errors: [],
}

function getFallbackErrorContent(detail = 'An unexpected error occurred.') {
  return {
    ...unknownErrorContent,
    detail,
  }
}

export async function unknownToErrorContentAsync(
  error: unknown,
): Promise<ErrorContent> {
  try {
    if (error instanceof Response) {
      const errorContent: unknown = await error.json()
      if (isErrorContent(errorContent)) return errorContent
    }
  } catch (e) {
    return getFallbackErrorContent('Error occurred while parsing json response')
  }
  return unknownToErrorContent(error)
}

export function unknownToErrorContent(error: unknown): ErrorContent {
  if (isErrorContent(error)) return error
  if (error instanceof ZodError) {
    return {
      detail: 'Validation Error:',
      status_code: 400,
      error_name: 'Validation Error',
      warnings: [],
      errors: error.issues.map((e) => ({
        code: e.code,
        description: e.message,
      })),
    } satisfies ErrorContent
  }
  if (error instanceof Error) {
    return {
      detail: error.message,
      error_name: error.name || 'NSUnexpectedError',
      status_code: 500,
      warnings: [],
      errors: [],
    }
  }
  if (isRouteErrorResponse(error)) {
    return {
      detail:
        typeof error.data === 'string'
          ? error.data
          : 'An unknown error occurred',
      error_name: `Response: ${error.status} ${error.statusText}`,
      status_code: error.status,
      warnings: [],
      errors: [],
    }
  }
  let info = ''
  if (typeof error === 'string') info = error
  if (typeof error === 'number') info = error.toString()
  const detail = `An unexpected error occurred${
    info != null ? `:\n${info}` : '.'
  }`
  return getFallbackErrorContent(detail)
}

export function useErrorContent(error: unknown) {
  const [errorContent, setErrorContent] = useState<ErrorContent>(
    getFallbackErrorContent('An unexpected error occurred.'),
  )
  useEffect(() => {
    ;(async () => {
      const err = await unknownToErrorContentAsync(error)
      setErrorContent(err)
    })()
  }, [error])

  return errorContent
}
