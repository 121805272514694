// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/ChartSelectDMMType.ts"
);
import.meta.hot.lastModified = "1731736109635.109";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumerates the possible DMM types for a chart select.
 */
export enum ChartSelectDMMType {
  METRIC = 'metric',
  DIMENSION = 'dimension',
}
